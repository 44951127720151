import { createRef, useCallback, useContext, useEffect, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Document, UI } from "@lex/lex-types";
import { UserContext } from "../context/UserContext";
import { BaseContext } from "../context/BaseContext";
import Menu from "../components/to-organise/Menu";
import Modal from "../components/to-organise/Modal";
import ImagePreview from "../components/custom-controls/ImagePreview";
import TooltipWrapper from "../components/to-organise/TooltipWrapper";
import '../assets/scss/images-page.scss';

const ImagesPage = () => {
    const { logout, decodedToken } = useContext(UserContext);
    const { updateCopiedImage, getFileUuids, generateImageURL, uploadFile } = useContext(BaseContext);
    const [images, setImages] = useState<(Document.FileInfo & { hidden?: boolean })[]>([]);
    const [loading, setLoading] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const fileInput = createRef<HTMLInputElement>();

    const navigate = useNavigate();

    const fetchImages = useCallback(async () => {
        setLoading(true);
        const filePaths = await getFileUuids(UI.FileTypes.IMAGE);
        if (filePaths) {
            setImages(filePaths.map(fPath => {
                const separator = '.';
                const parts = fPath.split(separator);
                const ext = `${parts.pop()}`;
                const ts = parseInt(parts.pop() || '0', 10);
                const name = parts.join(separator);
                return ({
                    name,
                    ts,
                    ext,
                    url: generateImageURL(fPath),
                })
            }))
        }
        setLoading(false);
    }, [generateImageURL, getFileUuids])

    const handleUploadImage = useCallback(async (file: File) => {
        setLoading(true);
        setShowUploadModal(false);
        await uploadFile(file, UI.FileTypes.IMAGE);
        await fetchImages();
        setLoading(false);
    }, [fetchImages, uploadFile])

    useEffect(() => {
        fetchImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Menu
                searchType='generic'
                searchPlaceholder='Cauta imagini ...'
                userName={`${decodedToken?.firstName} ${decodedToken?.lastName}`}
                onSearchGeneric={text => setImages(images.map(img => ({ ...img, hidden: !img.name.includes(text) })))}
                onHome={() => navigate('/')}
                onLogout={() => { logout(); navigate('/login'); }}
                onImagesClick={() => { }}
                onFilesClick={() => navigate('/files')}
                onSettingsClick={() => navigate('/settings')}
            />
            <div className='app-content-wrapper'>
                <div className='lex-images-page-title'>
                    Lista imaginilor
                    <TooltipWrapper
                        desc='Urca imagine noua'
                    >
                        <div className='lex-icon-button hover-green' onClick={() => setShowUploadModal(true)}>
                            <Icons.Upload />
                        </div>
                    </TooltipWrapper>
                </div>
                <div className='lex-images-list'>
                    {
                        loading
                            ? "Loading images ..."
                            : images.length
                                ? images
                                    .filter(img => !img.hidden)
                                    .map(image =>
                                        <ImagePreview
                                            key={`${image.name}-${image.ts}`}
                                            {...image}
                                            onClick={updateCopiedImage}
                                        />
                                    )
                                : <span className="lex-image-picker-info">Nu sunt imagini disponibile.</span>
                    }
                </div>
                {
                    showUploadModal
                        ? <Modal
                            title="Urca imagine noua"
                            type={UI.ModalTypes.INFO}
                            buttons={[
                                {
                                    type: UI.ModalButtonTypes.SAVE,
                                    text: 'Salveaza',
                                    onClick: () => {
                                        if (fileInput.current?.files?.length) handleUploadImage(fileInput.current.files[0])
                                    }
                                },
                                {
                                    type: UI.ModalButtonTypes.CANCEL,
                                    text: 'Inchide',
                                    onClick: () => setShowUploadModal(false)
                                }
                            ]}
                            body={<div className='d-flex flex-row'>
                                <span>Imagine</span>
                                <span className="lex-spacer-1" />
                                <Form.Control
                                    placeholder="Selecteaza fisierul"
                                    type='file'
                                    ref={fileInput}
                                />
                            </div>}
                        />
                        : null
                }
            </div>
        </>
    )
}

export default ImagesPage;
