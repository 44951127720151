import { DB } from '@lex/lex-types';
import * as Icons from 'react-bootstrap-icons';
import SearchResult from '../../search/SearchResult';
import '../../../assets/scss/rel-item.scss';

const DocumentLinkItem = ({
    rel_type,
    relatedDocId,
    state,
    dbId,
    meta,
    onDelete,
}: DB.RelResponse & { onDelete: () => void }) => {
    if (!meta) return null;
    return (
        <div className='lex-rel-item'>
            <div className='lex-rel-item-header'>
                <div className='lex-rel-item-type'>
                    {rel_type}
                </div>
                {/* <div className='lex-rel-item-date'>
                    REL_DATE
                </div> */}
                <div className='lex-rel-item-actions'>
                    <div className='lex-rel-item-actions-item remove' onClick={onDelete}>
                        <Icons.XCircle />
                    </div>
                </div>
            </div>
            <SearchResult
                {...meta}
                bookmarks={[]}
                docid={''} // so it can't open on click
                issuer={meta.issuer_name}
                issue_nr={meta.issuer_nr}
                mtime={meta.mtime || ''}
                onClick={() => { }}
            />
        </div>
    )
}

export default DocumentLinkItem;
