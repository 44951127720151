import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { UI } from "@lex/lex-types";
import FormField from "../components/to-organise/FormField";
import '../assets/scss/form.scss';

export type SelectOption = {
    label: string,
    value: string,
}

export type FormDataTemplate = {
    name: string,
    content: Omit<UI.FormField, 'onChange'>[],
}

export type FormData = {
    name: string,
    content: UI.FormField[],
}

export type FormParams = FormData & {
    onChange: (name: string, value: string | number | boolean | string[]) => void, // TODO
}

export const getEntryValue = (name: string, formData: FormData) => {
    const relevantEntry = formData.content.find(i => i.name === name);
    return relevantEntry?.value;
}

const insertEmptyFormValue = (entry: Omit<UI.FormField, 'onChange'>, onChange: (value: any) => void): UI.FormField => {
    switch (entry.type) {
        case UI.FormFieldTypes.SELECT:
            return ({ ...entry, type: UI.FormFieldTypes.SELECT, value: entry.value as string, onChange });
        case UI.FormFieldTypes.MULTI_SELECT:
            return ({ ...entry, type: UI.FormFieldTypes.MULTI_SELECT, value: entry.value as string[], onChange });
        case UI.FormFieldTypes.LIST:
            return ({ ...entry, type: UI.FormFieldTypes.LIST, value: [entry.value as string], onChange });
        case UI.FormFieldTypes.NUMBER:
            return ({ ...entry, type: UI.FormFieldTypes.NUMBER, value: entry.value as number, onChange });
        case UI.FormFieldTypes.BOOLEAN:
            return ({ ...entry, type: UI.FormFieldTypes.BOOLEAN, value: !!entry.value, onChange });
        case UI.FormFieldTypes.DATE:
            return ({ ...entry, type: UI.FormFieldTypes.DATE, value: entry.value as string, onChange });
        case UI.FormFieldTypes.STRING:
        default:
            return ({ ...entry, type: UI.FormFieldTypes.STRING, value: entry.value as string, onChange });
    }
}

// TODO - validate names being unique
export const useForms = (initialData: FormDataTemplate[]) => {
    const [formData, setFormData] = useState<FormParams[]>([]);

    const isValid = useMemo(() => {
        for (const form of formData) {
            if (form.content.some(e => !!e.error)) return false;
        }
        return true;
    }, [formData]);

    const updateFormData = (formName: string, optionName: string, value: string | number | boolean | string[]) => {
        setFormData(prev => prev.map(form =>
            form.name === formName
                ? {
                    ...form,
                    content: form.content.map(entry => {
                        if (entry.name !== optionName) {
                            return entry;
                        }
                        return { ...entry, value, error: entry.validate?.(value) || undefined } as UI.FormField;
                    }),
                }
                : form
        ))
    }

    const renderForm = useCallback((name: string) => {
        const relevantForm = formData.find(form => form.name === name);
        if (!relevantForm) {
            return <div className='form-error' > Error trying to render form {name} </div>
        }
        return (
            <Form {...relevantForm} />
        )
    }, [formData]);

    const getValuesAsObject = useCallback((formName: string, entryNames: string[]) => {
        const relevantForm = formData.find(i => i.name === formName);
        const result: Record<string, any> = {};
        if (relevantForm) {
            for (const entryName of entryNames) {
                result[entryName] = getEntryValue(entryName, relevantForm);
            }
        }
        return result;
    }, [formData]);

    useEffect(() => {
        setFormData(initialData.map(form => ({
            name: form.name,
            content: form.content.map(e => ({
                ...insertEmptyFormValue(e, (value) => updateFormData(form.name, e.name, value)),
                error: e.validate?.(e.value),
            })),
            onChange: (name: string, updatedValue: string | number | boolean | string[]) => updateFormData(form.name, name, updatedValue),
        })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { renderForm, getValuesAsObject, isValid };
}

export const Form = (props: FormParams) => {
    // const handleFormEntryChanged = (name: string, value: any) => {
    //     props.onChange(name, value);
    // }
    const { content } = props;
    const firstInputRef = useRef<HTMLElement>(null);

    useEffect(() => {
        // Focus on the first input element when the component mounts
        if (firstInputRef.current) {
            console.log(firstInputRef.current);
        }
        firstInputRef.current?.focus();
    }, []);

    return (
        <div className='lex-form'>
            {
                content.map((item, idx) =>
                    <React.Fragment key={item.name}>
                        <div className="form-group" key={item.name}>
                            <div className='d-flex flex-row align-items-center w-100' style={{ gap: '0.5rem' }}>
                                <FormField
                                    ref={idx === 0 ? firstInputRef : undefined}
                                    {...item}
                                    tabIndex={idx + 1}
                                />
                            </div>
                        </div>
                        {
                            item.separatorBelow
                                ? <hr />
                                : null
                        }
                    </React.Fragment>
                )
            }
        </div>
    )

}

export default useForms;
