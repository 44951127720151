import React, { forwardRef } from 'react';
import { UI } from "@lex/lex-types";
import { Form } from "react-bootstrap";
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import '../../assets/scss/form-field.scss';
import "react-datepicker/dist/react-datepicker.css";
import { DropdownWithSearch } from '../form-components/Dropdowns';
import dayjs from 'dayjs';
import { ro } from 'date-fns/locale/ro';
registerLocale('ro', ro);

const FieldValue = forwardRef((props: UI.FormField & { tabIndex?: number }, ref: any) => {
    const { name, type, value, readOnly, tabIndex, onChange } = props;
    switch (type) {
        case UI.FormFieldTypes.STRING:
            return <Form.Control
                ref={ref}
                tabIndex={tabIndex}
                type={type}
                value={value}
                disabled={readOnly}
                onChange={e => onChange(e.target.value)}
            />
        case UI.FormFieldTypes.NUMBER:
            return <Form.Control
                ref={ref}
                tabIndex={tabIndex}
                type={type}
                value={value}
                disabled={readOnly}
                onChange={e => onChange(parseInt(e.target.value, 10))}
            />
        case UI.FormFieldTypes.SELECT:
            return <DropdownWithSearch
                {...props}
                ref={ref}
                tabIndex={tabIndex}
                isMulti={false}
                onChange={onChange}
                options={props.options || []}
                value={value}
            />
        case UI.FormFieldTypes.MULTI_SELECT:
            return <DropdownWithSearch
                {...props}
                ref={ref}
                tabIndex={tabIndex}
                isMulti={true}
                onChange={onChange}
                options={props.options || []}
                value={value}
            />
        case UI.FormFieldTypes.BOOLEAN:
            return <Form.Switch
                ref={ref}
                tabIndex={tabIndex}
                checked={value}
                disabled={readOnly}
                onChange={e => onChange(!value)}
            />
        case UI.FormFieldTypes.DATE:
            return <DatePicker
                ref={ref}
                className='form-control'
                tabIndex={tabIndex}
                locale='ro'
                dateFormat="dd.MM.yyyy"
                selected={new Date(value)}
                disabled={readOnly}
                onChange={e => { if (e) { onChange(dayjs(e).format('YYYY-MM-DD')) } }}
            />
        // return <Form.Control
        //     ref={ref}
        //     tabIndex={tabIndex}
        //     type={type}
        //     value={value}
        //     disabled={readOnly}
        //     onChange={e => onChange(e.target.value)}
        // />
        case UI.FormFieldTypes.LIST:
            return <>{value}</>
    }
})

const FormField = forwardRef((props: UI.FormField & { forceRow?: boolean, tabIndex?: number }, ref: any) => {
    const { label, forceRow } = props;
    return (
        <div className={`lex-form-field w-100 ${forceRow ? 'force-row' : ''}`}>
            <div className='lex-form-field-label'>
                {label}
            </div>
            <div className='lex-form-field-value'>
                <FieldValue {...props} ref={ref} />
            </div>
            {
                props.error
                    ? <div className='lex-form-field-error'>
                        {props.error}
                    </div>
                    : null
            }
        </div>
    )
})

export default FormField;
