import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import * as Icons from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useCurrentEditor } from "@tiptap/react";
import { DB, UI, WS } from "@lex/lex-types";
import { BaseContext } from "../../context/BaseContext";
import { LoadingAndErrorsContext } from "../../context/LoadingAndErrorsContext";
import { usePersist } from "../../hooks/usePersist";
import { backendUrl } from "../../hooks/useApi";
import useWebSocket from "../../hooks/useWebSocket";
import Modal from "../to-organise/Modal";
import DiscreteDropdown from "../to-organise/DiscreteDropdown";
import DocumentLinksPanel from "../custom-controls/document-links/DocumentLinksPanel";
import TooltipWrapper from "../to-organise/TooltipWrapper";
import { Spinner } from "../to-organise/Loader";
import RevisionChain from "../to-organise/RevisionChain";
import dayjs from "dayjs";
import 'dayjs/locale/ro';
import { UserContext } from "../../context/UserContext";
import DatePicker, { registerLocale } from "react-datepicker";
import { ro } from 'date-fns/locale/ro';
registerLocale('ro', ro);

export const GeneratedTextHeader = ({
    publisher_name,
    publish_nr,
    publish_date,
}: DB.FrontendBackendMeta) => {
    const mofName = `M.Of.Nr.${publish_nr}.pdf`;
    return (
        <div style={{ height: 'auto', padding: '1rem 1.5rem', paddingBottom: 0, overflow: "hidden", fontSize: '10pt', fontFamily: 'Courier New' }}>
            {publisher_name} Nr. {publish_nr} din {dayjs(publish_date).locale('ro').format('DD MMMM YYYY')} (<a href={`${backendUrl}/api/files/file/${mofName}`} rel="no-referrer">Sursa Act: Monitorul Oficial</a>)
        </div>
    )
}

const DocumentHeader = ({
    id,
    notSaved = false, // whether the document is saved or not
    canEdit = false, // whether the user can edit the document meta
    meta,
    rels,
    revisions,
    liveDocId,
    onSave,
    onPublish,
    onClose,
    onChange,
}: {
    id: string,
    notSaved?: boolean,
    canEdit?: boolean,
    meta?: DB.FrontendBackendMeta,
    rels: DB.RelResponse[],
    revisions: DB.RevisionData[],
    liveDocId?: string,
    onSave?: () => void,
    onPublish?: () => void,
    onChange?: (meta: DB.FrontendBackendMeta, rels: DB.RelResponse[]) => void
    onClose: () => void,
}) => {
    const { staticData, emptyMeta, saveDocument, addDocument, publishDocument } = useContext(BaseContext);
    const { decodedToken } = useContext(UserContext);
    const [editingMetaField, setEditingMetaField] = useState('');
    const [localMeta, setLocalMeta] = useState<DB.FrontendBackendMeta>(meta || emptyMeta);
    const [localRels, setLocalRels] = useState<DB.RelResponse[]>(rels);
    const [showConfirmLeavePopup, setShowConfirmLeavePopup] = useState(false);
    const [showConfirmOverritePopup, setShowConfirmOverritePopup] = useState(false);

    const navigate = useNavigate();
    const { addMessage } = useContext(LoadingAndErrorsContext);
    const { editor } = useCurrentEditor();

    const canSaveAsNewRev = useMemo(() => {
        if (!liveDocId) {
            return true;
        }
        const sortedRevs = revisions.sort((a, b) => dayjs(a.rev_date).valueOf() < dayjs(b.rev_date).valueOf() ? 1 : -1)
        if (sortedRevs.length && sortedRevs[0].id === liveDocId) {
            return true;
        }
        return false;

    }, [revisions, liveDocId]);

    useWebSocket(
        `${backendUrl.replace('https://', 'wss://').replace('http://', 'ws://')}/api`,
        id,
        useCallback((e: MessageEvent) => {
            const parsedData: WS.WebSocketMessage = JSON.parse(e.data);
            if (parsedData.event === WS.EventTypes.OVERRIDE_OWNER) {
                const { email } = parsedData;
                addMessage({
                    type: 'warning',
                    text: <p style={{ marginBottom: 0 }}><b>{meta?.title}</b> a fost inchis deoarece {decodedToken?.email === email ? <>ai deschis documentul in alt tab / browser</> : <><b>{email}</b> a preluat documentul spre editare!</>} </p>,
                });
                navigate('/');
            }
        }, [addMessage, decodedToken?.email, meta?.title, navigate]),
        // () => {},
        // () => {console.log('wtf1')},
        // () => {console.log('wtf2')},
    )

    const saveDoc = useCallback(async (close = false) => {
        const content = editor?.getJSON();
        if (content) {
            if (id) {
                await saveDocument(content, localMeta, localRels.map(({ meta, ...rest }) => rest), id, true);
            } else {
                await addDocument(content, localMeta, localRels, [], undefined, true); // to do show/save rels
            }
        }
        onSave?.();
        if (close) {
            onClose();
        }
    }, [addDocument, editor, id, localMeta, localRels, onClose, onSave, saveDocument])

    const publishDoc = useCallback(async (newRev?: boolean) => {
        const content = editor?.getJSON();
        if (id && content) {
            await publishDocument(id, content, localMeta, localRels, !!newRev, liveDocId, true);
        }
        onPublish?.();
    }, [editor, id, liveDocId, localMeta, localRels, onPublish, publishDocument])


    const hasMetaChanges = useMemo(() => !_.isEqual(localMeta, meta) || !_.isEqual(localRels, rels)
        , [localMeta, localRels, meta, rels]);

    const discardChanges = useCallback(() => {
        if (hasMetaChanges || notSaved) {
            setShowConfirmLeavePopup(true);
        } else {
            onClose();
        }
    }, [hasMetaChanges, notSaved, onClose])

    const { updateValue, loading } = usePersist<{ meta: DB.FrontendBackendMeta, rels: DB.RelResponse[] }>({ meta: localMeta, rels: localRels }, saveDoc);

    const statusCode = useCallback((name: string) => staticData.docStatuses[name]
        , [staticData.docStatuses])

    const updateMetaAndRels = useCallback((newMeta: DB.FrontendBackendMeta, newRels: DB.RelResponse[]) => {
        setLocalMeta(newMeta);
        setLocalRels(newRels);
        updateValue({ meta: newMeta, rels: newRels });
        onChange?.(newMeta, newRels);
    }, [onChange, updateValue]);

    useEffect(() => {
        if (meta) {
            setLocalMeta(meta);
            setLocalRels(rels);
            updateValue({ meta, rels });
        }
    }, [meta, rels, updateValue])

    return (
        <>
            <div className='document-header'>
                <div className='document-header-main'>
                    <Form.Control
                        type='text'
                        className='document-header-title'
                        value={localMeta.title}
                        onChange={(e) => updateMetaAndRels({ ...localMeta, title: e.target.value }, localRels)}
                    />
                    {
                        notSaved || hasMetaChanges
                            ? <TooltipWrapper
                                desc={<span className='lex-regular-weight'>Documentul nu este salvat</span>}
                                placement='bottom'
                            >
                                <div className="document-header-not-saved">
                                    <Icons.ExclamationTriangleFill />
                                </div>
                            </TooltipWrapper>
                            : null
                    }
                    {
                        loading
                            ? <Spinner />
                            : null
                    }
                    {
                        revisions.length
                            ? <RevisionChain revisions={revisions} liveDocId={liveDocId || ''} />
                            : null
                    }
                    {
                        (liveDocId && canSaveAsNewRev) || !liveDocId
                            ? <div
                                className={`lex-button neutral md ${hasMetaChanges || notSaved ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (!hasMetaChanges && !notSaved) {
                                        publishDoc(true);
                                    }
                                }}
                            >
                                Publica {liveDocId ? 'revizie noua' : ''}
                            </div>
                            : null
                    }
                    {
                        liveDocId
                            ? <div
                                className={`lex-button neutral md ${hasMetaChanges || notSaved ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (!hasMetaChanges && !notSaved) {
                                        setShowConfirmOverritePopup(true);
                                    }
                                }}
                            >
                                Publica corectura
                            </div>
                            : null
                    }
                </div>
                <div className='document-header-secondary'>
                    <DiscreteDropdown
                        isMulti={false}
                        isOpen={editingMetaField === 'type_name'}
                        value={localMeta.type_name}
                        placeholder='Selecteaza tip act'
                        options={staticData.docTypes}
                        onClick={() => setEditingMetaField(prev => prev === 'type_name' ? '' : 'type_name')}
                        onClickOutside={() => setEditingMetaField(prev => prev === 'type_name' ? '' : prev)}
                        onItemClick={(e, item) => {
                            e.preventDefault();
                            setEditingMetaField('');
                            if (item !== localMeta.type_name) {
                                updateMetaAndRels({ ...localMeta, type_name: item }, localRels);
                            }
                        }}
                    />
                    <DiscreteDropdown
                        isMulti={false}
                        isOpen={editingMetaField === 'status_name'}
                        value={localMeta.status_name}
                        placeholder='Selecteaza tip act'
                        options={Object.keys(staticData.docStatuses)}
                        onClick={() => setEditingMetaField(prev => prev === 'status_name' ? '' : 'status_name')}
                        onClickOutside={() => setEditingMetaField(prev => prev === 'status_name' ? '' : prev)}
                        onItemClick={(e, item) => {
                            e.preventDefault();
                            setEditingMetaField('');
                            if (item !== localMeta.status_name) {
                                updateMetaAndRels({ ...localMeta, status_name: item }, localRels);
                            }
                        }}
                        renderer={(statusName) => <span className={`status status-${statusCode(statusName)}`}>{statusName}</span>}
                    />
                    <div className='document-header-entry'>
                        nr.
                        <Form.Control
                            name='issuer_nr'
                            type='number'
                            className='document-header-secondary'
                            value={localMeta.issuer_nr}
                            style={{
                                width: `${localMeta.issuer_nr.toString().length * 0.6}rem`
                            }}
                            onChange={(e) => updateMetaAndRels({ ...localMeta, issuer_nr: parseInt(e.target.value, 10) }, localRels)}
                        />
                        /
                        <Form.Control
                            name='issue_year'
                            type='number'
                            className='document-header-secondary'
                            value={localMeta.issue_year}
                            style={{
                                width: `${localMeta.issue_year.toString().length * 0.6}rem`
                            }}
                            onChange={(e) => updateMetaAndRels({ ...localMeta, issue_year: parseInt(e.target.value, 10) }, localRels)}
                        />
                    </div>
                    <div className='document-header-entry'>
                        M.Of.
                        <Form.Control
                            name='publish_nr'
                            type='number'
                            className='document-header-secondary'
                            value={localMeta.publish_nr}
                            style={{
                                width: `${localMeta.publish_nr.toString().length * 0.55}rem`
                            }}
                            onChange={(e) => updateMetaAndRels({ ...localMeta, publish_nr: parseInt(e.target.value, 10) }, localRels)}
                        />
                        /
                        <DatePicker
                            className='document-header-secondary'
                            locale='ro'
                            dateFormat="dd.MM.yyyy"
                            selected={new Date(localMeta.publish_date)}
                            onChange={e => { if (e) { updateMetaAndRels({ ...localMeta, publish_date: dayjs(e).format('YYYY-MM-DD') }, localRels) } }}
                        />
                        {/* <Form.Control
                            name='publish_date'
                            type='date'
                            className='document-header-secondary'
                            value={localMeta.publish_date}
                            style={{
                                textAlign: 'left',
                                width: `${localMeta.publish_date.length * 0.65}rem`
                            }}
                            onChange={(e) => updateMetaAndRels({ ...localMeta, publish_date: e.target.value }, localRels)}
                        /> */}
                    </div>
                    <DiscreteDropdown
                        isMulti={false}
                        isOpen={editingMetaField === 'issuer_name'}
                        label='Emitent: '
                        value={localMeta.issuer_name}
                        placeholder='Selecteaza emitent'
                        options={staticData.issuers}
                        onClick={() => setEditingMetaField(prev => prev === 'issuer_name' ? '' : 'issuer_name')}
                        onClickOutside={() => setEditingMetaField(prev => prev === 'issuer_name' ? '' : prev)}
                        onItemClick={(e, item) => {
                            e.preventDefault();
                            setEditingMetaField('');
                            if (item !== localMeta.issuer_name) {
                                updateMetaAndRels({ ...localMeta, issuer_name: item }, localRels)
                            }
                        }}
                    />
                    <DiscreteDropdown
                        isMulti
                        wrap
                        isOpen={editingMetaField === 'domains'}
                        label='Tematici: '
                        value={localMeta.domains}
                        placeholder='Selecteaza tematici'
                        options={staticData.domains.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)}
                        onClick={() => setEditingMetaField(prev => prev === 'domains' ? '' : 'domains')}
                        onClickOutside={() => setEditingMetaField(prev => prev === 'domains' ? '' : prev)}
                        onItemClick={(e, item) => {
                            e.preventDefault();
                            if (localMeta.domains.includes(item)) {
                                updateMetaAndRels({ ...localMeta, domains: localMeta.domains.filter(d => d !== item) }, localRels)
                            } else {
                                updateMetaAndRels({ ...localMeta, domains: [...localMeta.domains, item] }, localRels)
                            }
                        }}
                    />
                    <DiscreteDropdown
                        isMulti={false}
                        isOpen={editingMetaField === 'publisher_name'}
                        label='Publicat de: '
                        value={localMeta.publisher_name}
                        placeholder='Selecteaza'
                        options={staticData.publishers.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)}
                        onClick={() => setEditingMetaField(prev => prev === 'publisher_name' ? '' : 'publisher_name')}
                        onClickOutside={() => setEditingMetaField(prev => prev === 'publisher_name' ? '' : prev)}
                        onItemClick={(e, item) => {
                            e.preventDefault();
                            setEditingMetaField('');
                            if (item !== localMeta.publisher_name) {
                                updateMetaAndRels({ ...localMeta, publisher_name: item }, localRels)
                            }
                        }}
                    />
                    <div className='document-header-links'>
                        <DocumentLinksPanel
                            rels={localRels}
                            isOpen={editingMetaField === 'rels'}
                            onClick={() => setEditingMetaField(prev => prev === 'rels' ? '' : 'rels')}
                            onClose={() => setEditingMetaField(prev => prev === 'rels' ? '' : prev)}
                            onChange={newRels => updateMetaAndRels(localMeta, newRels)}
                        />
                    </div>
                    <div className='document-header-actions'>
                        <div
                            className={`lex-button success md ${!notSaved && !hasMetaChanges ? 'disabled' : ''}`}
                            onClick={() => saveDoc()}
                        >
                            Salveaza
                            {
                                loading
                                    ? <Spinner />
                                    : null
                            }
                        </div>
                        <div className='lex-button danger md' onClick={discardChanges}>Inchide</div>
                    </div>
                </div>
            </div>
            {
                showConfirmLeavePopup
                    ?
                    <Modal
                        type={UI.ModalTypes.DANGER}
                        title='Vrei sa salvezi schimbarile facute documentului?'
                        body={<>Modificarile facute vor fi pierdute daca documentul nu este salvat!</>}
                        buttons={[{
                            type: UI.ModalButtonTypes.SAVE,
                            text: 'Salveaza',
                            onClick: () => saveDoc(true)
                        },
                        {
                            type: UI.ModalButtonTypes.DONT_SAVE,
                            text: 'Nu salva',
                            onClick: onClose,
                        },
                        {
                            type: UI.ModalButtonTypes.CANCEL,
                            text: 'Anuleaza',
                            onClick: () => setShowConfirmLeavePopup(false)
                        }]}
                    />
                    : null
            }
            {
                showConfirmOverritePopup
                    ?
                    <Modal
                        type={UI.ModalTypes.DANGER}
                        title='Esti sigur(a) ca vrei sa suprascrii varianta existenta?'
                        body={<>Publicarea corectiei va suprascrie documentul live existent. Actiunea este irevocabila!</>}
                        buttons={[{
                            type: UI.ModalButtonTypes.SAVE,
                            text: 'Suprascrie',
                            onClick: () => publishDoc(false)
                        },
                        {
                            type: UI.ModalButtonTypes.CANCEL,
                            text: 'Anuleaza',
                            onClick: () => setShowConfirmOverritePopup(false)
                        }]}
                    />
                    : null
            }
        </>
    )
}

export default DocumentHeader;
