import { useContext, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import dayjs from "dayjs";
import { DB, UI } from "@lex/lex-types";
import { BaseContext } from "../../context/BaseContext";
import TooltipWrapper from "../to-organise/TooltipWrapper";
import Modal from "../to-organise/Modal";

import '../../assets/scss/search-results.scss'

const SearchResult = ({
    title,
    type_name,
    issue_nr,
    issue_year,
    publish_nr,
    publish_date,
    status_name,
    docid,
    owner,
    isDraft,
    mtime,
    showUser = false,
    hideStatus = false,
    canOpen = false,
    canDelete = false,
    requireConfirmationToOpen = false,
    noConfirmDelete = false,
    onClick,
    onDelete,
}: DB.SearchResponseEntry
    & {
        showUser?: boolean,
        hideStatus?: boolean,
        canOpen?: boolean,
        canDelete?: boolean,
        requireConfirmationToOpen?: boolean,
        noConfirmDelete?: boolean,
        onClick: () => void,
        onDelete?: () => void
    }) => {
    const { staticData } = useContext(BaseContext);
    const [showExpandedDeleteButton, setShowExpandedDeleteButton] = useState(false);
    const [showDeleteDraftPopup, setShowDeleteDraftPopup] = useState(false);
    const [showTakeOwnershipPopup, setShowTakeOnwershipPopup] = useState(false);
    return (
        <>
            <div
                className={`lex-search-result ${showUser && isDraft ? 'show-user' : ''} ${isDraft ? 'draft' : 'live'} ${(!canOpen || requireConfirmationToOpen) ? 'cannot-open' : ''}`}
                onClick={() => { if (canOpen && !requireConfirmationToOpen) onClick() }}
            >
                {
                    showUser && isDraft
                        ? <div className='lex-search-result-user'>
                            <div className='lex-search-result-user-name'>
                                {owner || 'Neasignat'}
                                {
                                    requireConfirmationToOpen
                                        ? <TooltipWrapper
                                            desc='Deblocheaza documentul'
                                        >
                                            <div className='lex-doc-override-owner' style={{ cursor: 'pointer' }} onClick={() => setShowTakeOnwershipPopup(true)}>
                                                <Icons.LockFill />
                                            </div>
                                        </TooltipWrapper>
                                        : null
                                }
                            </div>
                            <div className='lex-search-result-user-date'>
                                {mtime ? dayjs(mtime).format('HH:mm:ss \u00A0\u00A0DD-MM-YYYY') : ''}
                            </div>
                        </div>
                        : null
                }
                <div className='lex-search-result-main'>
                    <div className='lex-search-result-main-row'>
                        <b>{type_name}</b>&nbsp;nr. {issue_nr}/{issue_year} | M.Of. {publish_nr}/{dayjs(publish_date).format('DD.MM.YYYY')}
                    </div>
                    <div className='lex-search-result-main-row lex-search-result-main-title'>
                        {title}
                    </div>
                </div>
                <div className='lex-search-result-statuses'>
                    {
                        !hideStatus
                            ? <span className={`ms-auto status status-${staticData.docStatuses[status_name] || 'unknown'} `}><b>{status_name}</b></span>
                            : null
                    }
                    {
                        isDraft
                            ? <div className='lex-search-result-draft'>Draft</div>
                            : null
                    }
                </div>
                {
                    canDelete
                        ? <div
                            className={`lex-search-result-delete ${showExpandedDeleteButton ? 'expanded' : ''}`}
                            onMouseEnter={() => setShowExpandedDeleteButton(true)}
                            onMouseLeave={() => setShowExpandedDeleteButton(false)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (noConfirmDelete) {
                                    onDelete?.();
                                } else {
                                    setShowDeleteDraftPopup(true);
                                }
                            }}
                        >
                            <div className='lex-search-result-delete-icon' >
                                <Icons.XCircle />
                            </div>
                            <div className={`lex-search-result-delete-text ${showExpandedDeleteButton ? 'expanded' : ''}`}>
                                Sterge
                            </div>
                        </div>
                        : null
                }
            </div>
            {
                showDeleteDraftPopup
                    ? <Modal
                        type={UI.ModalTypes.DANGER}
                        title="Esti sigur(a) ca vrei sa stergi documentul?"
                        body={<>Prin confirmarea actiunii, documentul <b>({type_name} {issue_nr}/{issue_year}) "{title}"</b> va fi sters. Actiunea este irevocabila!</>}
                        buttons={[
                            {
                                type: UI.ModalButtonTypes.OK,
                                text: 'Sterge',
                                onClick: () => { onDelete?.(); setShowDeleteDraftPopup(false) },
                            },
                            {
                                type: UI.ModalButtonTypes.CANCEL,
                                text: 'Anuleaza',
                                onClick: () => setShowDeleteDraftPopup(false),
                            }
                        ]}
                    />
                    : null
            }
            {
                showTakeOwnershipPopup
                    ? <Modal
                        type={UI.ModalTypes.DANGER}
                        title="Esti sigur(a) ca vrei sa preiei documentul?"
                        body={<>Prin confirmarea actiunii, documentul <b>({type_name} {issue_nr}/{issue_year}) "{title}"</b> va fi asignat tie. Orice modificari facute la document de catre {owner} in ultimele 5 minute pot fi pierdute!</>}
                        buttons={[
                            {
                                type: UI.ModalButtonTypes.OK,
                                text: 'Preia',
                                onClick: () => {
                                    console.log('ma-ta'); onClick(); setShowTakeOnwershipPopup(false)
                                },
                            },
                            {
                                type: UI.ModalButtonTypes.CANCEL,
                                text: 'Anuleaza',
                                onClick: () => setShowTakeOnwershipPopup(false),
                            }
                        ]}
                    />
                    : null
            }
        </>
    )
}

export default SearchResult;
