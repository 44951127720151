import { useCallback, useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { Form } from "react-bootstrap";
import Draggable from "react-draggable";

import '../../assets/scss/add-link.scss';

const isValidURL = (value: string) => {
    try {
        new URL(value);
        return true;
    } catch (error) {
        return false;
    }
}

const AddLink = ({
    value,
    onChange,
    onClose,
}: {
    value?: string,
    onChange: (docId: string) => void,
    onClose: () => void,
}) => {

    const wrapperRef = useOutsideClick(() => onClose());

    const [linkValue, setLinkValue] = useState('');

    const handleAddExternalLink = useCallback(() => {
        if (isValidURL(linkValue)) {
            onChange(linkValue);
            // TODO = else, show error
        }
    }, [linkValue, onChange]);

    return (
        <Draggable>
            <div className='lex-add-link-inner left' ref={wrapperRef}>
                <Form.Control
                    type='url'
                    placeholder='Link ...'
                    value={linkValue}
                    onChange={e => setLinkValue(e.target.value)}
                />
                <div
                    className={`lex-button success ${!isValidURL(linkValue) ? 'disabled' : 'pointer'}`}
                    onClick={handleAddExternalLink}
                >
                    Adauga
                </div>
            </div>
        </Draggable>
    )
}

export default AddLink;
