import * as Icons from 'react-bootstrap-icons';
import { DB, UI } from '@lex/lex-types';
import SearchDocumentsBox from '../search/SearchDocumentsBox';
import '../../assets/scss/menu.scss';
import Logo from './Logo';
import SearchBoxGeneric from '../search/SearchBoxGeneric';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getCookie } from '../../hooks/useApi';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ro } from 'date-fns/locale/ro';
import '../../assets/scss/create-arj.scss';

registerLocale('ro', ro);

const Menu = ({
    userName,
    searchType,
    searchPlaceholder,
    onSearchDocuments,
    onSearchGeneric,
    onHome,
    onImagesClick,
    onFilesClick,
    onSettingsClick,
    onLogout,
}: {
    userName: string,
    searchType: 'generic' | 'documents',
    searchPlaceholder?: string,
    onSearchDocuments?: (results: DB.SearchResponseEntry[]) => void,
    onSearchGeneric?: (text: string) => void,
    onHome: () => void,
    onImagesClick: () => void,
    onSettingsClick: () => void,
    onFilesClick: () => void,
    onLogout: () => void,
}) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [arjDate, setArjDate] = useState(dayjs().format('YYYY-MM-DD'));

    const wrapperRef = useOutsideClick(() => setShowDatePicker(false));

    return (
        <>
            <div className='app-menu'>
                <div className='app-menu-logo' onClick={onHome}>
                    <Logo variant={UI.Variants.DARK} size={UI.Sizes.LARGE} showName hideLogo showVersion />
                </div>
                {
                    searchType === 'documents'
                        ? <SearchDocumentsBox placeholder={searchPlaceholder} onSearch={results => onSearchDocuments?.(results)} />
                        : null
                }
                {
                    searchType === 'generic'
                        ? <SearchBoxGeneric placeholder={searchPlaceholder} onSearch={text => onSearchGeneric?.(text)} />
                        : null
                }
                <div className='app-menu-actions'>
                    <div className='app-menu-user-name me-2'>
                        {userName}
                    </div>
                    <div className='app-menu-actions-icon' onClick={() => setShowDatePicker(true)} style={{ position: 'relative' }}>
                        <Icons.Archive />
                        {
                            showDatePicker
                                ? <div className='lex-arj-date-picker' ref={wrapperRef}>
                                    <DatePicker
                                        className='form-control'
                                        locale='ro'
                                        dateFormat="dd.MM.yyyy"
                                        selected={new Date(arjDate)}
                                        onChange={e => { if (e) { setArjDate(dayjs(e).format('YYYY-MM-DD')) } }}
                                    />
                                    <div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '0.5rem' }}>
                                        <a className='lex-button md success' href={`${(window as any)._env_.REACT_APP_BACKEND_URL}/api/docs/create-arj/${arjDate}?token=${getCookie('jwtToken')}`} onClick={e => { e.stopPropagation(); setShowDatePicker(false); }}>Creaza ARJ</a>
                                        <div className='lex-button md' onClick={e => { e.stopPropagation(); setShowDatePicker(false) }}>Anuleaza</div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className='app-menu-actions-icon' onClick={onImagesClick}>
                        <Icons.Images />
                    </div>
                    <div className='app-menu-actions-icon' onClick={onFilesClick}>
                        <Icons.FilePdf />
                    </div>
                    {/* <div className='app-menu-actions-icon' onClick={onSettingsClick}>
                    <Icons.GearFill />
                </div> */}
                    <div className='app-menu-actions-icon' onClick={onLogout}>
                        <Icons.Power />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Menu;
