import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import * as Icons from 'react-bootstrap-icons';
import { useCurrentEditor } from '@tiptap/react';
import { Document, UI } from "@lex/lex-types";
import { v4 as uuidv4 } from 'uuid';
import ColorPicker from "../to-organise/ColorPicker";
import AddExternalLink from "../custom-controls/AddExternalLink";
import AddLinkToDoc from "../custom-controls/AddLinkToDoc";
import ImagePicker from "../custom-controls/ImagePicker";
import TooltipWrapper from "../to-organise/TooltipWrapper";
import Modal from "../to-organise/Modal";
import ShortcutsInfo from "../shortcuts/ShortcutsInfo";
import ListStylePicker from "../custom-controls/ListStylePicker";
import { BookmarkType } from "../editor-overrides/Bookmark";
import Label from "../non-functional/Label";
import { BookmarkItem } from "../dom-manipulation/link-bookmarks";
import '../../assets/scss/color-picker.scss';
import '../../assets/scss/add-bookmark.scss';
import { AddEndBookmark, AddStartBookmark } from "../custom-controls/AddBookmark";
import AddSymbol from "../custom-controls/AddSymbol";

const editorCombinedStyles = {
    none: {
        bold: false,
        italic: false,
        underline: false,
        strikethrough: false,
        color: '',
    },
    additions: {
        bold: true,
        italic: false,
        underline: true,
        strikethrough: false,
        color: '#008000',
    },
    deletions: {
        bold: false,
        italic: true,
        underline: false,
        strikethrough: true,
        color: '#dd1f20',
    },
    explanations: {
        bold: false,
        italic: true,
        underline: false,
        strikethrough: false,
        color: '#3468e3',
    }
} as const;

// const listStyles = {
//     [Document.ListStyleTypes.NONE]: {
//         text: 'Fara numerotare',
//         icon: <Icons.ListTask />,
//     },
//     [Document.ListStyleTypes.DECIMAL]: {
//         text: '1, 2, 3, ...',
//         icon: <Icons.ListOl />
//     },
//     [Document.ListStyleTypes.LOWER_LATIN]: {
//         text: 'a, b, c, ...',
//         icon: <Icons.ListOl />
//     },
//     [Document.ListStyleTypes.LOWER_ROMAN]: {
//         text: 'i, ii, iii, ...',
//         icon: <Icons.ListOl />
//     },
//     [Document.ListStyleTypes.UPPER_LATIN]: {
//         text: 'A, B, C, ...',
//         icon: <Icons.ListOl />
//     },
//     [Document.ListStyleTypes.UPPER_ROMAN]: {
//         text: 'I, II, III, ...',
//         icon: <Icons.ListOl />
//     }
// } as const;

export type TextStyle = {
    bold?: boolean,
    italic?: boolean,
    underline?: boolean,
    strikethrough?: boolean,
    emphasize?: boolean,
    scriptType?: Document.ScriptType,
    href?: string,
}

const EditorControls = ({
    bookmarks,
}: {
    bookmarks: BookmarkItem[],
}) => {
    const [showColorPicker, setShowColorPicker] = useState(false);

    const [showAddLink, setShowAddLink] = useState(false);
    const [showAddExternalLink, setShowAddExternalLink] = useState(false);

    const [showImagePicker, setShowImagePicker] = useState(false);
    const [showShortcutsInfo, setShowShortcutsInfo] = useState(false);
    const [showListStylePicker, setShowListStylePicker] = useState(false);

    const [showAddStartBookmark, setShowAddStartBookmark] = useState(false);
    const [showAddEndBookmark, setShowAddEndBookmark] = useState(false);

    const [showSymbolPicker, setShowSymbolPicker] = useState(false);

    const { editor } = useCurrentEditor();

    const handleSetPredefinedStyle = useCallback((props: keyof typeof editorCombinedStyles) => {
        const t = editorCombinedStyles[props];
        if (editor) {
            if (t.bold) {
                editor.commands.setBold()
            } else {
                editor.commands.unsetBold()
            }

            if (t.italic) {
                editor.commands.setItalic()
            } else {
                editor.commands.unsetItalic()
            }

            if (t.underline) {
                editor.commands.setUnderline();
            } else {
                editor.commands.unsetUnderline();
            }

            if (t.strikethrough) {
                editor.commands.setStrike();
            } else {
                editor.commands.unsetStrike();
            }
            if (t.color) {
                editor.chain().focus().setColor(t.color).run();
            } else {
                editor.chain().focus().unsetColor().run();
            }
        }
    }, [editor]);

    const handleAddImage = useCallback((url: string) => {
        editor?.commands.setImage({ src: url });
    }, [editor?.commands])

    const handleKeyPress = useCallback((event: KeyboardEvent) => {

        if (event.ctrlKey && event.shiftKey && event.key === 'H') {
            setShowAddLink(true);
            setShowAddExternalLink(false);
            setShowImagePicker(false);
            setShowShortcutsInfo(false);
            setShowListStylePicker(false);
            setShowColorPicker(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    if (!editor) return null;

    return (
        <div className='lex-editor-controls'>
            {/* Basic font styles */}
            <TooltipWrapper
                desc={<span className='lex-bold'>Bold</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive("bold") ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                >
                    <Icons.TypeBold />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-italic'>Italic</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive("italic") ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                >
                    <Icons.TypeItalic />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-underline'>Underline</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive("underline") ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                >
                    <Icons.TypeUnderline />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-strikethrough'>Strikethrough</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive("strike") ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                >
                    <Icons.TypeStrikethrough />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-subscript'>Subscript</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive('subscript') ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleSubscript().run()}
                >
                    <Icons.Subscript />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-subscript'>Superscript</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive('superscript') ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleSuperscript().run()}
                >
                    <Icons.Superscript />
                </div>
            </TooltipWrapper>

            <div className='lex-spacer-1' />
            {/* Text align */}
            <TooltipWrapper
                desc={<span>Align Left</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive({ textAlign: 'left' }) ? 'active' : ''}`}
                    onClick={() => {
                        if (!editor.isActive({ textAlign: 'left' })) {
                            editor.chain().focus().setTextAlign('left').run();
                        }
                    }}
                >
                    <Icons.TextLeft />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span>Align Center</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive({ textAlign: 'center' }) ? 'active' : ''}`}
                    onClick={() => {
                        if (!editor.isActive({ textAlign: 'center' })) {
                            editor.chain().focus().setTextAlign('center').run();
                        }
                    }}
                >
                    <Icons.TextCenter />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={<span>Align Right</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive({ textAlign: 'right' }) ? 'active' : ''}`}
                    onClick={() => {
                        if (!editor.isActive({ textAlign: 'right' })) {
                            editor.chain().focus().setTextAlign('right').run();
                        }
                    }}
                >
                    <Icons.TextRight />
                </div>
            </TooltipWrapper>
            <div className='lex-spacer-1' />
            {/* Lists */}
            <div className='list-style-picker-wrapper'>
                <TooltipWrapper
                    desc={<span className='lex-regular-weight'>Bullet list</span>}
                    placement='bottom'
                >
                    <div
                        className={`lex-button transparent small ${editor.isActive('bulletList') || editor.isActive('squareBulletList') || editor.isActive('emptyCircleBulletList') || editor.isActive('tickBulletList') ? 'active' : ''}`}
                        onClick={() => setShowListStylePicker(prev => !prev)}
                    >
                        <Icons.ListUl className='lex-button-icon' />
                    </div>

                </TooltipWrapper>
                {
                    showListStylePicker
                        ? <ListStylePicker
                            editor={editor}
                            onClose={() => setShowListStylePicker(false)}
                        />
                        : null
                }
            </div>

            <TooltipWrapper
                desc={<span className='lex-regular-weight'>Numbered list</span>}
                placement='bottom'
            >
                <div
                    className={`lex-button transparent small ${editor.isActive('orderedList') ? 'active' : ''}`}
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                >
                    <Icons.ListUl className='lex-button-icon' />
                </div>

            </TooltipWrapper>
            <div className='lex-spacer-1' />
            {/* Paragraph style */}
            <Form.Select
                onChange={(e) => {
                    if (e.target.value !== 'unset') {
                        // editor.chain().focus().unsetBlockquote().run();
                        switch (e.target.value) {
                            case Document.ParagraphTypes.PARAGRAPH:
                                editor.chain().focus().setParagraph().setTextAlign('left').run();
                                break;
                            // case Document.BlockTypes.HEADER_1:
                            //     editor.chain().focus().setHeading({ level: 1 }).setTextAlign('center').setBold().run();
                            //     break;
                            // case Document.BlockTypes.HEADER_2:
                            //     editor.chain().focus().setHeading({ level: 2 }).setTextAlign('center').setBold().run();
                            //     break;
                            // case Document.BlockTypes.HEADER_3:
                            //     editor.chain().focus().setHeading({ level: 3 }).setTextAlign('center').setBold().run();
                            //     break;
                            // case Document.BlockTypes.HEADER_4:
                            //     editor.chain().focus().setHeading({ level: 4 }).setTextAlign('center').setBold().run();
                            //     break;
                            // case Document.BlockTypes.HEADER_5:
                            //     editor.chain().focus().setHeading({ level: 5 }).setTextAlign('center').setBold().run();
                            //     break;
                            // case Document.BlockTypes.HEADER_6:
                            //     editor.chain().focus().setHeading({ level: 6 }).setTextAlign('center').setBold().run();
                            //     break;
                            // case Document.BlockTypes.BLOCKQUOTE:
                            // editor.chain().focus().toggleBlockquote().run();

                            case Document.ParagraphTypes.TITLE:
                                editor.chain().focus().setHeading({ level: 5 }).setTextAlign('center').setBold().run();
                                break;
                            case Document.ParagraphTypes.SUBTITLE:
                                editor.chain().focus().setHeading({ level: 6 }).setTextAlign('center').setBold().run();
                                break;
                            case 'title':
                                break;
                        }

                    }
                }}
                className='w-auto'
                style={{ textTransform: 'capitalize' }}
            // value={undefined} // TODO - how to get this value
            >
                <option value={Document.ParagraphTypes.PARAGRAPH} selected={editor.isActive('paragraph')}>Paragraf</option>
                {
                    [Document.ParagraphTypes.TITLE, Document.ParagraphTypes.SUBTITLE]
                        .map((option, idx) =>
                            <option
                                value={option}
                                key={idx}
                                selected={editor.isActive('heading', { level: idx + 5 })} // TODO
                            >
                                {option.replace(/-/g, ' ')}
                            </option>
                        )
                }
                {/* This doesn't work properly */}
                {/* <option value={Document.BlockTypes.BLOCKQUOTE} selected={editor.isActive('blockquote')}>Block quote</option> */}
            </Form.Select>
            <div className='lex-spacer-1' />
            {/* Predefined styles */}
            <div className='lex-color-picker-wrapper'>
                <TooltipWrapper
                    desc={<span className='lex-regular-weight'>Text color</span>}
                    placement='bottom'
                >
                    <div
                        className='lex-button transparent small'
                        onClick={() => setShowColorPicker(prev => !prev)}
                    >
                        <Icons.Palette className='lex-button-icon' />
                    </div>

                </TooltipWrapper>
                {
                    showColorPicker
                        ? <ColorPicker
                            color={editor.getAttributes('textStyle').color || '#212529'}
                            onChange={color => editor.chain().focus().setColor(color).run()}
                            onClose={() => setShowColorPicker(false)}
                        />
                        : null
                }
            </div>
            <div className='lex-spacer-1' />
            <TooltipWrapper
                desc={<span className=''>Normal</span>}
                placement='bottom'
            >
                <div
                    className='lex-button transparent small'
                    onClick={() => handleSetPredefinedStyle('none')}
                >
                    n
                </div>

            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-regular-weight lex-track-plus'>Adaugiri</span>}
                placement='bottom'
            >
                <div
                    className='lex-button transparent small square-2-rem lex-track-plus'
                    onClick={() => handleSetPredefinedStyle('additions')}
                >
                    a
                </div>

            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-regular-weight lex-track-minus'>Stergeri</span>}
                placement='bottom'
            >
                <div
                    className='lex-button transparent small square-2-rem lex-track-minus'
                    onClick={() => handleSetPredefinedStyle('deletions')}
                >
                    s
                </div>

            </TooltipWrapper>
            <TooltipWrapper
                desc={<span className='lex-regular-weight lex-track-explain'>Explicatii</span>}
                placement='bottom'
            >
                <div
                    className='lex-button transparent small square-2-rem lex-track-explain'
                    onClick={() => handleSetPredefinedStyle('explanations')}
                >
                    e
                </div>

            </TooltipWrapper>
            <div className='lex-spacer-1' />
            <div className='lex-add-symbol-wrapper'>
                <TooltipWrapper
                    desc='Adauga simbol'
                >
                    <div className='lex-button transparent small' onClick={() => setShowSymbolPicker(prev => !prev)}>
                        <Icons.Threads />
                    </div>
                </TooltipWrapper>
                {
                    showSymbolPicker
                        ? <AddSymbol
                            onChange={(value: string) => {
                                editor
                                    .chain()
                                    .insertContent({ type: 'text', text: value })
                                    .focus()
                                    .run();
                                setShowSymbolPicker(false);
                            }}
                            onClose={() => setShowSymbolPicker(false)}
                        />
                        : null
                }
            </div>
            <div className='lex-spacer-1' />
            <div className='lex-image-picker-wrapper'>
                <TooltipWrapper
                    desc='Adauga imagine'
                >
                    <div className='lex-button transparent small' onClick={() => setShowImagePicker(prev => !prev)}>
                        <Icons.Images />
                    </div>
                </TooltipWrapper>
                {
                    showImagePicker
                        ? <ImagePicker
                            onClose={() => setShowImagePicker(false)}
                            canMaximize
                            onAdd={handleAddImage}
                        />
                        : null
                }
            </div>
            <div className='lex-spacer-1' />
            <div className='lex-add-bookmark-wrapper'>
                <TooltipWrapper
                    desc={<span className='lex-regular-weight'>Adauga bookmark</span>}
                    placement='bottom'
                >
                    <div
                        className='lex-button transparent small'
                        onClick={() => setShowAddStartBookmark(true)}
                    // onClick={() => editor.chain().focus().insertContent({ type: 'lex-bookmark', content: [{ type: 'text', text: 'my bookmark' }, { type: 'text', text: '-', marks: [{ type: 'bookmark-line' }] }], attrs: { uid: "1" } }).run()}
                    >
                        <Icons.BookmarkPlus className='lex-button-icon' />
                    </div>
                </TooltipWrapper>
                {
                    showAddStartBookmark
                        ? <AddStartBookmark
                            onSubmit={(name: string) => {
                                const id = uuidv4();
                                editor
                                    .chain()
                                    .focus()
                                    .insertContent({
                                        type: 'lex-bookmark',
                                        content: [{
                                            type: 'text',
                                            text: name,
                                        },
                                        {
                                            type: 'text',
                                            text: '-',
                                            marks: [{
                                                type: 'bookmark-line',
                                                attrs: {
                                                    id,
                                                }
                                            }],

                                        }],
                                        attrs: {
                                            uid: id,
                                            type: BookmarkType.START,
                                        },
                                    })
                                    .run();
                                setShowAddStartBookmark(false);
                            }}
                            onClose={() => setShowAddStartBookmark(false)}
                        />
                        : null
                }
            </div>
            <div className='lex-add-bookmark-wrapper'>
                <TooltipWrapper
                    desc={<span className='lex-regular-weight'>Adauga sfarsit de bookmark</span>}
                    placement='bottom'
                >
                    <div
                        className='lex-button transparent small'
                        onClick={() => setShowAddEndBookmark(true)}
                    // onClick={() => editor.chain().focus().insertContent({ type: 'lex-bookmark', content: [{ type: 'text', text: 'my bookmark' }], attrs: { type: BookmarkType.END, uid: "2", pairUid: "1" } }).run()}
                    >
                        <Icons.Bookmarks className='lex-button-icon' />
                    </div>
                </TooltipWrapper >
                {
                    showAddEndBookmark
                        ? <AddEndBookmark
                            options={bookmarks.filter(b => !b.hasPair)}
                            onSubmit={({ name, uid }) => {
                                editor
                                    .chain()
                                    .focus()
                                    .insertContent({
                                        type: 'lex-bookmark',
                                        content: [{
                                            type: 'text',
                                            text: name,
                                        }],
                                        attrs: {
                                            uid,
                                            type: BookmarkType.END,
                                        },
                                    })
                                    .run();
                                setShowAddEndBookmark(false);
                            }}
                            onClose={() => setShowAddEndBookmark(false)}

                        />
                        : null
                }
            </div>
            <div className='lex-add-link-wrapper'>
                <TooltipWrapper
                    desc={<span className='lex-regular-weight'>Adauga link catre alt document</span>}
                    placement='bottom'
                >
                    <div className='lex-button transparent small' onClick={() => setShowAddLink(true)}>
                        <Icons.Link className='lex-button-icon' />
                    </div>
                </TooltipWrapper>
                {
                    showAddLink
                        ? <AddLinkToDoc
                            value={editor.getAttributes('link').href}
                            onChange={(value) => {
                                editor.chain().focus().extendMarkRange('link').setLink({ href: value }).run();
                                setShowAddLink(false);
                            }}
                            onRemove={() => {
                                editor.chain().focus().extendMarkRange('link').unsetLink().run();
                                setShowAddLink(false);
                            }}
                            onClose={() => setShowAddLink(false)}
                        />
                        : null
                }
            </div>
            <div className='lex-add-link-wrapper'>
                <TooltipWrapper
                    desc={<span className='lex-regular-weight'>Adauga link extern</span>}
                    placement='bottom'
                >
                    <div className='lex-button transparent small' onClick={() => setShowAddExternalLink(true)}>
                        <Icons.Link45deg className='lex-button-icon' />
                        <Label
                            text="ext"
                            size="xxs"
                            theme="light"
                            variant="transparent"
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                            }}
                        />
                    </div>
                </TooltipWrapper>
                {
                    showAddExternalLink
                        ? <AddExternalLink
                            value={editor.getAttributes('link').href}
                            onChange={(value) => {
                                editor.chain().focus().extendMarkRange('link').setLink({ href: value }).run();
                                setShowAddLink(false);
                            }}
                            onClose={() => setShowAddExternalLink(false)}
                        />
                        : null
                }
            </div>
            <div className='lex-spacer-1' />
            <TooltipWrapper
                desc={editor.can().undo() ? 'Undo' : ''}
            >
                <div className={`lex-button ms-auto transparent small ${!editor.can().undo() ? 'disabled-discrete' : ''}`} onClick={() => editor.chain().focus().undo().run()}>
                    <Icons.ArrowCounterclockwise className='lex-button-icon' />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                desc={editor.can().redo() ? 'Redo' : ''}
            >
                <div className={`lex-button transparent small ${!editor.can().redo() ? 'disabled-discrete' : ''}`} onClick={() => editor.chain().focus().redo().run()}>
                    <Icons.ArrowClockwise className='lex-button-icon' />
                </div>
            </TooltipWrapper>
            <div className='lex-spacer-1' />
            <TooltipWrapper
                desc='Informatii despre scurtaturi'
            >
                <div className='lex-button transparent small' onClick={() => setShowShortcutsInfo(true)}>
                    <Icons.InfoCircle className='lex-button-icon' />
                </div>
            </TooltipWrapper>
            {
                showShortcutsInfo
                    ? <Modal
                        title="Scurtaturi"
                        body={<ShortcutsInfo />}
                        buttons={[{
                            text: 'Inchide',
                            type: UI.ModalButtonTypes.OK,
                            onClick: () => setShowShortcutsInfo(false),
                        }]}
                    />
                    : null
            }
        </div >
    )
}

export default EditorControls;
